// import { authRoles } from './auth/authRoles'

const isAdmin = window.localStorage.getItem('isAdmin');
const accessToken = window.localStorage.getItem('accessToken');
const linkUrl = "https://analysis.suna.ai/home/" + accessToken;

var navigations;

const navigations1 = [
    {
        label: 'Strategy',
        type: 'label',
    },
    {
        name: 'Publish Manager',
        path: '/strategy/Bundles',
        // icon: 'dashboard',
        type: 'main',
    },
    {
        name: 'SSR',
        children: [
            {
                name: 'Overview',
                path: '/strategy/SSR/Overview_SSR',
            },
            {
                name: 'Strategy',
                path: '/strategy/SSR/Strategy_SSR',
            },

            {
                name: 'AccountSetting',
                path: '/strategy/SSR/SeettingAccount_SSR',

            },
            {
                name: 'TradeSetting',
                path: '/strategy/SSR/SeettingTrade_SSR',
            },
            {
                name: 'Trading',
                path: '/strategy/SSR/Trading_SSR',
            },

            // {
            //     name: 'Logging',
            //     path: '/strategy/SSR/Logging_SSR',
            // },

        ],
    },
    {
        name: 'SBTM',
        children: [
            {
                name: 'Overview',
                path: '/strategy/SBTM/Overview',
            },
            {
                name: 'Strategy',
                path: '/strategy/SBTM/Strategy',
            },
            {
                name: 'Setting',
                path: '/strategy/SBTM/Seetting',
            },
            {
                name: 'Trading',
                path: '/strategy/SBTM/Trading',
            },
            {
                name: 'Controlling',
                path: '/strategy/SBTM/Controlling',
            },
            // {
            //     name: 'Logging',
            //     path: '/strategy/SBTM/Logging',
            // },


        ],
    },
    {
        name: 'SCP',
        children: [
            {
                name: 'Overview',
                path: '/strategy/SCP/Overview_SCP',
            },
            {
                name: 'Strategy',
                path: '/strategy/SCP/Strategy_SCP',
            },
            {
                name: 'Setting',
                path: '/strategy/SCP/Seetting_SCP',
            },
            {
                name: 'Trading',
                path: '/strategy/SCP/Trading_SCP',
            },
            {
                name: 'Series',
                path: '/strategy/SCP/Series_SCP',
            },
            {
                name: 'Event',
                path: '/strategy/SCP/Event_SCP',
            },
            // {
            //     name: 'Logging',
            //     path: '/strategy/SCP/Logging_SCP',
            // },


        ],
    },
    {
        name: 'SPO',
        children: [
            {
                name: 'Overview',
                path: '/strategy/SPO/Overview_SPO',
            },
            {
                name: 'Strategy',
                path: '/strategy/SPO/Strategy_SPO',
            },
            {
                name: 'Setting',
                path: '/strategy/SPO/Seetting_SPO',
            },
            {
                name: 'Trading',
                path: '/strategy/SPO/Trading_SPO',
            },
            {
                name: 'Pools',
                path: '/strategy/SPO/Pools_SPO',
            },
            // {
            //     name: 'Logging',
            //     path: '/strategy/SPO/Logging_SPO',
            // },


        ],
    },
    {
        name: 'SRB',
        children: [
            {
                name: 'Overview',
                path: '/strategy/SRB/Overview_SRB',
            },
            {
                name: 'Strategy',
                path: '/strategy/SRB/Strategy_SRB',
            },
            {
                name: 'Setting',
                path: '/strategy/SRB/Seetting_SRB',
            },
            {
                name: 'Trading',
                path: '/strategy/SRB/Trading_SRB',
            },
            {
                name: 'Groups',
                path: '/strategy/SRB/Groups_SRB',
            },
            // {
            //     name: 'Logging',
            //     path: '/strategy/SRB/Logging_SRB',
            // },


        ],
    },
    {
        name: 'SRBV',
        children: [
            {
                name: 'Overview',
                path: '/strategy/SRBV/Overview_SRBV',
            },
            {
                name: 'Strategy',
                path: '/strategy/SRBV/Strategy_SRBV',
            },
            {
                name: 'Setting',
                path: '/strategy/SRBV/Seetting_SRBV',
            },
            {
                name: 'Trading',
                path: '/strategy/SRBV/Trading_SRBV',
            },
            {
                name: 'Groups',
                path: '/strategy/SRBV/Groups_SRBV',
            },
            // {
            //     name: 'Logging',
            //     path: '/strategy/SRBV/Logging_SRBV',
            // },


        ],
    },


    {
        name: 'dashboard',
        path: '/others/Dashboard',
        icon: 'dashboard',
        type: 'main',
    },
    {
        name: 'configuration',
        path: '/others/Configuration',
        icon: 'build',
        type: 'main',
    },

    {
        name: 'analysis',
        path: linkUrl,
        icon: 'timeline',
        type: 'extLink',
    },
    {
        name: 'errors',
        path: '/others/Errors',
        icon: 'error',
        type: 'main',
    },
    {
        name: 'Logging',
        path: '/strategy/Logging',
        icon: 'description',
        type: 'main',
    },

];
const navigations2 = [
    {
        label: 'Strategy',
        type: 'label',
    },
    {
        name: 'Publish Manager',
        path: '/strategy/Bundles',
        // icon: 'dashboard',
        type: 'main',
    },
    {
        name: 'SSR',
        children: [
            {
                name: 'Overview',
                path: '/strategy/SSR/Overview_SSR',
            },
            {
                name: 'Strategy',
                path: '/strategy/SSR/Strategy_SSR',
            },
            {
                name: 'AccountSetting',
                path: '/strategy/SSR/SeettingAccount_SSR',
            },
            {
                name: 'TradeSetting',
                path: '/strategy/SSR/SeettingTrade_SSR',
            },
            {
                name: 'Trading',
                path: '/strategy/SSR/Trading_SSR',
            },

            // {
            //     name: 'Logging',
            //     path: '/strategy/SSR/Logging_SSR',
            // },



        ],
    },
    {
        name: 'SBTM',
        children: [
            {
                name: 'Overview',
                path: '/strategy/SBTM/Overview',
            },
            {
                name: 'Strategy',
                path: '/strategy/SBTM/Strategy',
            },
            {
                name: 'Setting',
                path: '/strategy/SBTM/Seetting',
            },
            {
                name: 'Trading',
                path: '/strategy/SBTM/Trading',
            },
            {
                name: 'Controlling',
                path: '/strategy/SBTM/Controlling',
            },
            // {
            //     name: 'Logging',
            //     path: '/strategy/SBTM/Logging',
            // },


        ],
    },
    {
        name: 'SCP',
        children: [
            {
                name: 'Overview',
                path: '/strategy/SCP/Overview_SCP',
            },
            {
                name: 'Strategy',
                path: '/strategy/SCP/Strategy_SCP',
            },
            {
                name: 'Setting',
                path: '/strategy/SCP/Seetting_SCP',
            },
            {
                name: 'Trading',
                path: '/strategy/SCP/Trading_SCP',
            },
            {
                name: 'Series',
                path: '/strategy/SCP/Series_SCP',
            },
            {
                name: 'Event',
                path: '/strategy/SCP/Event_SCP',
            },
            // {
            //     name: 'Logging',
            //     path: '/strategy/SCP/Logging_SCP',
            // },


        ],
    },
    {
        name: 'SPO',
        children: [
            {
                name: 'Overview',
                path: '/strategy/SPO/Overview_SPO',
            },
            {
                name: 'Strategy',
                path: '/strategy/SPO/Strategy_SPO',
            },
            {
                name: 'Setting',
                path: '/strategy/SPO/Seetting_SPO',
            },
            {
                name: 'Trading',
                path: '/strategy/SPO/Trading_SPO',
            },
            {
                name: 'Pools',
                path: '/strategy/SPO/Pools_SPO',
            },
            // {
            //     name: 'Logging',
            //     path: '/strategy/SPO/Logging_SPO',
            // },


        ],
    },
    {
        name: 'SRB',
        children: [
            {
                name: 'Overview',
                path: '/strategy/SRB/Overview_SRB',
            },
            {
                name: 'Strategy',
                path: '/strategy/SRB/Strategy_SRB',
            },
            {
                name: 'Setting',
                path: '/strategy/SRB/Seetting_SRB',
            },
            {
                name: 'Trading',
                path: '/strategy/SRB/Trading_SRB',
            },
            {
                name: 'Groups',
                path: '/strategy/SRB/Groups_SRB',
            },
            // {
            //     name: 'Logging',
            //     path: '/strategy/SRB/Logging_SRB',
            // },


        ],
    },
    {
        name: 'SRBV',
        children: [
            {
                name: 'Overview',
                path: '/strategy/SRBV/Overview_SRBV',
            },
            {
                name: 'Strategy',
                path: '/strategy/SRBV/Strategy_SRBV',
            },
            {
                name: 'Setting',
                path: '/strategy/SRBV/Seetting_SRBV',
            },
            {
                name: 'Trading',
                path: '/strategy/SRBV/Trading_SRBV',
            },
            {
                name: 'Groups',
                path: '/strategy/SRBV/Groups_SRBV',
            },
            // {
            //     name: 'Logging',
            //     path: '/strategy/SRBV/Logging_SRBV',
            // },


        ],
    },

    {
        name: 'dashboard',
        path: '/others/Dashboard',
        icon: 'dashboard',
        type: 'main',
    },
    {
        name: 'configuration',
        path: '/others/Configuration',
        icon: 'build',
        type: 'main',
    },

    {
        name: 'analysis',
        path: linkUrl,
        icon: 'timeline',
        type: 'extLink',
    },
    {
        name: 'errors',
        path: '/others/Errors',
        icon: 'error',
        type: 'main',
    },
    {
        name: 'Logging',
        path: '/strategy/Logging',
        icon: 'description',
        type: 'main',
    },

];

if (isAdmin)
    navigations = navigations1;
else
    navigations = navigations2;
export { navigations };
