import '../fake-db'
import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { Router, Switch, Route, BrowserRouter } from 'react-router-dom'
import AppContext from './contexts/AppContext'
import history from 'history.js'
import routes from './RootRoutes'
import { Store } from './redux/Store'
import myStore from './redux/myStore'
import { GlobalCss, MatxSuspense, MatxTheme, MatxLayout } from 'app/components'
import sessionRoutes from './views/sessions/SessionRoutes'
import AuthGuard from './auth/AuthGuard'
import { AuthProvider } from 'app/contexts/JWTAuthContext'
import { SettingsProvider } from 'app/contexts/SettingsContext'
import { UrlProvider } from 'app/contexts/URLContext'

const App = () => {

    const baseUrl = "https://qompoundserver.qompass-solutions.de";
    //const localUrl = "https://localhost:5070";
    const baseURL_Account_Symbol = baseUrl;
    const baseURL_Strategy_AccountSetting = baseURL_Account_Symbol;
    const baseURL_Strategy_AccountSetting_SCP = baseURL_Account_Symbol;

    // const interval = setInterval(() => {
    //     const currentTime = new Date().toLocaleString('de-DE', { timeZone: 'Europe/Berlin' });
    //     localStorage.setItem("CEST_Time", currentTime);
    // }, 1000);

    useEffect(() => {
        setInterval(() => {
            const currentTime = new Date().toLocaleString('de-DE', { timeZone: 'Europe/Berlin' });
            localStorage.setItem("CEST_Time", currentTime);
        }, 1000);

    }, []);


    return (
        <AppContext.Provider value={{ routes }}>
            <Provider store={Store}>
                <Provider store={myStore}>
                    <SettingsProvider>
                        <UrlProvider value={{ baseURL_Account_Symbol, baseURL_Strategy_AccountSetting, baseURL_Strategy_AccountSetting_SCP }}>
                            <MatxTheme>
                                <GlobalCss />
                                <BrowserRouter basename={process.env.PUBLIC_URL}>
                                    <Router history={history}>
                                        <AuthProvider>
                                            <MatxSuspense>
                                                <Switch>
                                                    {/* AUTHENTICATION PAGES (SIGNIN, SIGNUP ETC.) */}
                                                    {sessionRoutes.map((item, i) => (
                                                        <Route
                                                            key={i}
                                                            path={item.path}
                                                            component={item.component}
                                                        />
                                                    ))}
                                                    {/* AUTH PROTECTED DASHBOARD PAGES */}
                                                    <AuthGuard>
                                                        <MatxLayout />{' '}
                                                        {/* RETURNS <Layout1/> component */}
                                                    </AuthGuard>
                                                </Switch>
                                            </MatxSuspense>
                                        </AuthProvider>
                                    </Router>
                                </BrowserRouter>
                            </MatxTheme>
                        </UrlProvider>
                    </SettingsProvider>
                </Provider>
            </Provider>
        </AppContext.Provider>
    )
}

export default App
