import React, { createContext, useEffect, useReducer, useContext } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
import urlContext from 'app/contexts/URLContext'

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }

    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000
    console.log(decodedToken)
    return decodedToken.exp > currentTime
}

const setSession = (accessToken) => {
    // console.log('accessToken=', accessToken);
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
        console.log('delete accessToken')
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => {},
    register: () => Promise.resolve(),
    autologin: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    // eslint-disable-next-line
    const {
        baseURL_Account_Symbol,
        // baseURL_Strategy_accountSetting,
        // baseURL_Strategy_AccountSetting_SCP,
    } = useContext(urlContext)

    // const LogInUrl = baseURL_Account_Symbol + '/api/users/login';

    const login = async (email, password) => {
        // const response = await axios.post('/api/auth/login', {
        //     email,
        //     password,
        // })
        // console.log('User.data=', response.data);

        // const { accessToken, user } = response.data

        // setSession(accessToken)

        // dispatch({
        //     type: 'LOGIN',
        //     payload: {
        //         user,
        //     },
        // })

        await axios
            .post(baseURL_Account_Symbol + '/api/users/login', {
                email,
                password,
            })
            .then((res) => {
                console.log('user data=', res)

                if (res.data.success) {
                    const accessToken = res.data.auth_token
                    // const accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsImlhdCI6MTYzNDg1MjM2MSwiZXhwIjoxNjM1NDU3MTYxfQ.msZ_av0-YZKXw71RfH9onEc10y-0BIQwYEBgpz7dYac';
                    // const accessToken = jwt.sign({ userId: res.data.info._id }, JWT_SECRET, {
                    //     expiresIn: JWT_VALIDITY,
                    // })
                    const user = {
                        id: res.data.info._id,
                        role: 'SA',
                        name: res.data.info.name,
                        username: res.data.info.name,
                        email: res.data.info.email,
                        avatar: '/assets/images/face-6.jpg',
                        // avatar: '/assets/images/face-6.jpg',
                        age: 25,
                    }
                    setSession(accessToken)
                    dispatch({
                        type: 'LOGIN',
                        payload: {
                            user,
                        },
                    })

                    if (res.data.info.isAdmin)
                        localStorage.setItem('isAdmin', true)
                    else localStorage.removeItem('isAdmin')
                }
            })
    }

    const autologin = async (token) => {

        var config = {
            method: 'get',
            // url: 'https://demo.suna.ai/api/users/relogin',
            url: baseURL_Account_Symbol + '/api/users/relogin',
            headers: { Authorization: 'Bearer ' + token },
        }

        axios(config)
            .then(function (res) {
                const user = {
                    id: res.data._id,
                    role: 'SA',
                    name: res.data.name,
                    username: res.data.name,
                    email: res.data.email,
                    avatar: '/assets/images/face-6.jpg',
                    age: 25,
                }
                setSession(token)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: true,
                        user,
                    },
                })

                if (res.data.isAdmin) localStorage.setItem('isAdmin', true)
                else localStorage.removeItem('isAdmin')
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    const register = async (email, username, password) => {
        const response = await axios.post('/api/auth/register', {
            email,
            username,
            password,
        })

        const { accessToken, user } = response.data

        setSession(accessToken)

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const logout = () => {
        const accessToken = window.localStorage.getItem('accessToken')
        // console.log('accessToken=', accessToken);
        setSession(null)
        dispatch({ type: 'LOGOUT' })

        var axios = require('axios')

        var config = {
            method: 'post',
            url: baseURL_Account_Symbol + '/api/users/logout',
            headers: {
                Authorization: 'Bearer ' + accessToken,
            },
        }

        axios(config)
            .then(function (response) {
                console.log(response.data)
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    useEffect(() => {
        ;(async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')

                if (accessToken && isValidToken(accessToken)) {
                    setSession(accessToken)

                    var axios = require('axios')

                    var config = {
                        method: 'get',
                        url: baseURL_Account_Symbol + '/api/users/relogin',
                        headers: {
                            Authorization: 'Bearer ' + accessToken,
                        },
                    }

                    await axios(config).then(function (res) {
                        // console.log(JSON.stringify(res.data));
                        const user = {
                            id: res.data._id,
                            role: 'SA',
                            name: res.data.name,
                            username: res.data.name,
                            email: res.data.email,
                            avatar: '/assets/images/face-6.jpg',
                            // avatar: '/assets/images/face-6.jpg',
                            age: 25,
                        }
                        dispatch({
                            type: 'INIT',
                            payload: {
                                isAuthenticated: true,
                                user,
                            },
                        })
                    })

                    // const response = await axios.get('/api/auth/profile')
                    // const { user } = response.data

                    // dispatch({
                    //     type: 'INIT',
                    //     payload: {
                    //         isAuthenticated: true,
                    //         user,
                    //     },
                    // })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [baseURL_Account_Symbol])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
                autologin,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
