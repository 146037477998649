import React from 'react'
import { authRoles } from '../../auth/authRoles'


const accessToken = window.localStorage.getItem('accessToken');
const linkUrl = "https://analysis.suna.ai/home/" + accessToken;


const strategyRoutes = [
    /////////////////// SSR //////////////////////////////////
    {
        path: '/strategy/SSR/Overview_SSR',
        component: React.lazy(() => import('./SSR/Overview_SSR')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/SSR/Strategy_SSR',
        component: React.lazy(() => import('./SSR/Strategy_SSR')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/SSR/SeettingAccount_SSR',
        component: React.lazy(() => import('./SSR/SeettingAccount_SSR')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/SSR/Trading_SSR',
        component: React.lazy(() => import('./SSR/Trading_SSR')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/SSR/SeettingTrade_SSR',
        component: React.lazy(() => import('./SSR/SeettingTrade_SSR')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/SSR/Logging_SSR',
        component: React.lazy(() => import('./SSR/Logging_SSR')),
        auth: authRoles.sa,
    },

    /////////////////// SBTM //////////////////////////////////
    {
        path: '/strategy/SBTM/Overview',
        component: React.lazy(() => import('./SBTM/Overview')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/SBTM/Logging',
        component: React.lazy(() => import('./SBTM/Logging')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/SBTM/Strategy',
        component: React.lazy(() => import('./SBTM/Strategy')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/SBTM/Seetting',
        component: React.lazy(() => import('./SBTM/Seetting')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/SBTM/Trading',
        component: React.lazy(() => import('./SBTM/Trading')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/SBTM/Controlling',
        component: React.lazy(() => import('./SBTM/Controlling')),
        auth: authRoles.sa,
    },


    /////////////////// SCP //////////////////////////////////
    {
        path: '/strategy/SCP/Overview_SCP',
        component: React.lazy(() => import('./SCP/Overview_SCP')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/SCP/Logging_SCP',
        component: React.lazy(() => import('./SCP/Logging_SCP')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/SCP/Strategy_SCP',
        component: React.lazy(() => import('./SCP/Strategy_SCP')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/SCP/Seetting_SCP',
        component: React.lazy(() => import('./SCP/Seetting_SCP')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/SCP/Trading_SCP',
        component: React.lazy(() => import('./SCP/Trading_SCP')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/SCP/Series_SCP',
        component: React.lazy(() => import('./SCP/Series_SCP')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/SCP/Event_SCP',
        component: React.lazy(() => import('./SCP/Event_SCP')),
        auth: authRoles.sa,
    },

    /////////////////// SPO //////////////////////////////////
    {
        path: '/strategy/SPO/Overview_SPO',
        component: React.lazy(() => import('./SPO/Overview_SPO')),
        auth: authRoles.sa,
    },
    // {
    //     path: '/strategy/SPO/Logging_SPO',
    //     component: React.lazy(() => import('./SPO/Logging_SPO')),
    //     auth: authRoles.sa,
    // },
    {
        path: '/strategy/SPO/Strategy_SPO',
        component: React.lazy(() => import('./SPO/Strategy_SPO')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/SPO/Seetting_SPO',
        component: React.lazy(() => import('./SPO/Seetting_SPO')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/SPO/Trading_SPO',
        component: React.lazy(() => import('./SPO/Trading_SPO')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/SPO/Pools_SPO',
        component: React.lazy(() => import('./SPO/Pools_SPO')),
        auth: authRoles.sa,
    },

    /////////////////// SRB //////////////////////////////////
    {
        path: '/strategy/SRB/Overview_SRB',
        component: React.lazy(() => import('./SRB/Overview_SRB')),
        auth: authRoles.sa,
    },
    // {
    //     path: '/strategy/SRB/Logging_SRB',
    //     component: React.lazy(() => import('./SRB/Logging_SRB')),
    //     auth: authRoles.sa,
    // },
    {
        path: '/strategy/SRB/Strategy_SRB',
        component: React.lazy(() => import('./SRB/Strategy_SRB')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/SRB/Seetting_SRB',
        component: React.lazy(() => import('./SRB/Seetting_SRB')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/SRB/Trading_SRB',
        component: React.lazy(() => import('./SRB/Trading_SRB')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/SRB/Groups_SRB',
        component: React.lazy(() => import('./SRB/Groups_SRB')),
        auth: authRoles.sa,
    },

    /////////////////// SRBV //////////////////////////////////
    {
        path: '/strategy/SRBV/Overview_SRBV',
        component: React.lazy(() => import('./SRBV/Overview_SRBV')),
        auth: authRoles.sa,
    },
    // {
    //     path: '/strategy/SRBV/Logging_SRBV',
    //     component: React.lazy(() => import('./SRBV/Logging_SRBV')),
    //     auth: authRoles.sa,
    // },
    {
        path: '/strategy/SRBV/Strategy_SRBV',
        component: React.lazy(() => import('./SRBV/Strategy_SRBV')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/SRBV/Seetting_SRBV',
        component: React.lazy(() => import('./SRBV/Seetting_SRBV')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/SRBV/Trading_SRBV',
        component: React.lazy(() => import('./SRBV/Trading_SRBV')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/SRBV/Groups_SRBV',
        component: React.lazy(() => import('./SRBV/Groups_SRBV')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/Bundles',
        component: React.lazy(() => import('./Bundles')),
        auth: authRoles.sa,
    },
    {
        path: '/strategy/Logging',
        component: React.lazy(() => import('./Logging')),
        auth: authRoles.sa,
    },

    //////////////////////////////////////////////////////////////////////////
    {
        path: '/others/Dashboard',
        component: React.lazy(() => import('../others/Dashboard')),
        auth: authRoles.sa,
    },

    {
        path: '/others/Configuration',
        component: React.lazy(() => import('../others/Configuration')),
        auth: authRoles.sa,
    },

    {
        path: linkUrl,
        component: <div></div>,
        auth: authRoles.sa,
    },
    {
        path: '/others/Errors',
        component: React.lazy(() => import('../others/Errors')),
        auth: authRoles.sa,
    },




]

export default strategyRoutes