import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useAuth from 'app/hooks/useAuth'
import history from 'history.js'

const AutoLogin = () => {

    const { userToken } = useParams()

    const { autologin } = useAuth()

    useEffect(() => {
        async function rootLogin() {
            try {
                await autologin(userToken)
                history.push('/')
            } catch (e) {
                console.log(e)
            }
        }

        rootLogin()
    }, [userToken, autologin])

    return <div>{userToken}</div>
}

export default AutoLogin