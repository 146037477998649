
import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
     name: "symbolToggle",
     initialState: {
          SystemError: [],
          RelevanceClr: ['#87a2a3', '#34eb49', '#ebdc34', '#eb6834', '#eb4034'],
          CurURL: '',
          PrevSymbolDatas: [],
          TradingTableIndex: 2,
          SystemType: localStorage.getItem("Current_System_Type"),
          strategyKind: '',
          SymbolFilter: '',
          StrategyFilter: '',
          AccountFilter: '',
          StartDateFilter: '',
          EndDateFilter: '',
          StartTimeFilter: '',
          EndTimeFilter: '',
          SbtmAccountUpdate: false,
          selectedAccountState: 0,
          ActiveName: "",
          TableShow: false,
          TopBar3Show: false,
          TopBar4Show: false,
          TopBar5Show: false,
          Symbols: [],
          SymbolsInfo: [],
          Accounts: [],
          Strategies: [],
          SCPStrategies: [],
          SPOStrategies: [],
          SRBStrategies: [],
          SRBVStrategies: [],
          SSRStrategies: [],
          SBTMAccounts: [],
          timerId: 0,
          loggingTimerId_SCP: 0,
          onLine: false,
          sbtmAPI: [],
          sbtmLog: [],
          scpAPI: [],
          scpLog: [],
          controlfilters_1: '',
          controlfilters_2: '',
          controlfilters_3: '',
          controlTableData: [],
          Brokers: [],
          MoneyMethod: [],
          ConnectionInfo: [],
          SymbolPIPDigit: []
     },
     reducers: {
          getSystemError: (state, action) => {
               state.SystemError = action.payload;
          },
          getSymbolPIPDigit: (state, action) => {
               state.SymbolPIPDigit = action.payload;
          },
          getCurURL: (state, action) => {
               state.CurURL = action.payload;
          },
          getConnectionInfo: (state, action) => {
               state.ConnectionInfo = action.payload;
          },
          getMoneyMethod: (state, action) => {
               state.MoneyMethod = action.payload;
          },
          getPrevSymbolDatas: (state, action) => {
               state.PrevSymbolDatas = action.payload;
          },
          getTradingTableIndex: (state, action) => {
               state.TradingTableIndex = action.payload;
          },
          getStrategyKind: (state, action) => {
               state.strategyKind = action.payload;
          },
          getStartDateFilter: (state, action) => {
               state.StartDateFilter = action.payload;
          },
          getEndDateFilter: (state, action) => {
               state.EndDateFilter = action.payload;
          },
          getStartTimeFilter: (state, action) => {
               state.StartTimeFilter = action.payload;
          },
          getEndTimeFilter: (state, action) => {
               state.EndTimeFilter = action.payload;
          },
          getAccountFilter: (state, action) => {
               state.AccountFilter = action.payload;
          },
          getSymbolFilter: (state, action) => {
               state.SymbolFilter = action.payload;
          },
          getStrategyFilter: (state, action) => {
               state.StrategyFilter = action.payload;
          },
          getSbtmAccountUpdate: (state, action) => {
               state.SbtmAccountUpdate = action.payload;
          },
          getSelectedAccountState: (state, action) => {
               state.selectedAccountState = action.payload;
          },
          getActiveName: (state, action) => {
               state.ActiveName = action.payload;
          },
          getTopBar3: (state, action) => {
               state.TopBar3Show = action.payload;
          },
          getTopBar4: (state, action) => {
               state.TopBar4Show = action.payload;
          },
          getTopBar5: (state, action) => {
               state.TopBar5Show = action.payload;
          },
          getBrokers: (state, action) => {
               state.Brokers = action.payload;
          },
          getControlTableData: (state, action) => {
               state.controlTableData = action.payload;
          },
          getControlfilters_1: (state, action) => {
               state.controlfilters_1 = action.payload;
          },
          getControlfilters_2: (state, action) => {
               state.controlfilters_2 = action.payload;
          },
          getControlfilters_3: (state, action) => {
               state.controlfilters_3 = action.payload;
          },
          getSymbolShowVariable: (state, action) => {
               state.TableShow = action.payload;
          },
          getSymbolKinds: (state, action) => {
               state.Symbols = action.payload;
          },
          getSymbolsInfo: (state, action) => {
               state.SymbolsInfo = action.payload;
          },
          getAllAccountNames: (state, action) => {
               state.Accounts = action.payload;
          },
          getStrategies: (state, action) => {
               state.Strategies = action.payload;
          },
          getSCPStrategies: (state, action) => {
               state.SCPStrategies = action.payload;
          },
          getSPOStrategies: (state, action) => {
               state.SPOStrategies = action.payload;
          },
          getSRBStrategies: (state, action) => {
               state.SRBStrategies = action.payload;
          },
          getSRBVStrategies: (state, action) => {
               state.SRBVStrategies = action.payload;
          },
          getSSRStrategies: (state, action) => {
               state.SSRStrategies = action.payload;
          },
          getSBTMAccounts: (state, action) => {
               state.SBTMAccounts = action.payload;
          },
          getTimerId: (state, action) => {
               state.timerId = action.payload;
          },
          getLoggingTimerId_SCP: (state, action) => {
               state.loggingTimerId_SCP = action.payload;
          },
          getOnLine: (state, action) => {
               state.onLine = action.payload;
          },
          getAPI_sbtm: (state, action) => {
               state.sbtmAPI = action.payload;
          },
          getAPI_scp: (state, action) => {
               state.scpAPI = action.payload;
          },
          getLog_sbtm: (state, action) => {
               state.sbtmLog = action.payload;
          },
          getLog_scp: (state, action) => {
               state.scpLog = action.payload;
          },
          getSystemType: (state, action) => {
               state.SystemType = action.payload;
          },
     }
});

export const {
     getSystemError,
     getSymbolPIPDigit,
     getCurURL,
     getConnectionInfo,
     getMoneyMethod,
     getPrevSymbolDatas,
     getTradingTableIndex,
     getSystemType,
     getSymbolsInfo,
     getStrategyKind,
     getStrategyFilter,
     getSymbolFilter,
     getAccountFilter,
     getEndDateFilter,
     getStartDateFilter,
     getEndTimeFilter,
     getStartTimeFilter,
     getSbtmAccountUpdate,
     getSelectedAccountState,
     getActiveName,
     getTopBar3,
     getTopBar4,
     getTopBar5,
     getSymbolShowVariable,
     getSymbolKinds,
     getAllAccountNames,
     getStrategies,
     getSCPStrategies,
     getSPOStrategies,
     getSRBStrategies,
     getSRBVStrategies,
     getSSRStrategies,
     getSBTMAccounts,
     getTimerId,
     getLoggingTimerId_SCP,
     getOnLine,
     getAPI_sbtm,
     getAPI_scp,
     getLog_sbtm,
     getLog_scp,
     getControlTableData,
     getControlfilters_1,
     getControlfilters_2,
     getControlfilters_3,
     getBrokers,
} = slice.actions;

export const selectRelevanceClr = (state) => state.information.RelevanceClr;
export const selectSystemError = (state) => state.information.SystemError;
export const selectCurURL = (state) => state.information.CurURL;
export const selecConnectionInfo = (state) => state.information.ConnectionInfo;
export const selectMoneyMethod = (state) => state.information.MoneyMethod;
export const selectPrevSymbolDatas = (state) => state.information.PrevSymbolDatas;
export const selectSymbolPIPDigit = (state) => state.information.SymbolPIPDigit;
export const selectSystemType = (state) => state.information.SystemType;
export const selectTradingTableIndex = (state) => state.information.TradingTableIndex;
export const selectSymbolsInfo = (state) => state.information.SymbolsInfo;
export const selectStrategyKind = (state) => state.information.strategyKind;
export const selectStartDateFilter = (state) => state.information.StartDateFilter;
export const selectEndDateFilter = (state) => state.information.EndDateFilter;
export const selectStartTimeFilter = (state) => state.information.StartTimeFilter;
export const selectEndTimeFilter = (state) => state.information.EndTimeFilter;
export const selectAccountFilter = (state) => state.information.AccountFilter;
export const selectSymbolFilter = (state) => state.information.SymbolFilter;
export const selectStrategyFilter = (state) => state.information.StrategyFilter;
export const selectSbtmAccountUpdate = (state) => state.information.SbtmAccountUpdate;
export const selectSelectedAccountState = (state) => state.information.selectedAccountState;
export const selectActiveName = (state) => state.information.ActiveName;
export const selectSymbolTableShow = (state) => state.information.TableShow;
export const selectTopBar3 = (state) => state.information.TopBar3Show;
export const selectTopBar4 = (state) => state.information.TopBar4Show;
export const selectTopBar5 = (state) => state.information.TopBar5Show;
export const selectSymbolKinds = (state) => state.information.Symbols;
export const selectAllAccountNames = (state) => state.information.Accounts;
export const selectStrategies = (state) => state.information.Strategies;
export const selectSCPStrategies = (state) => state.information.SCPStrategies;
export const selectSPOStrategies = (state) => state.information.SPOStrategies;
export const selectSRBStrategies = (state) => state.information.SRBStrategies;
export const selectSRBVStrategies = (state) => state.information.SRBVStrategies;
export const selectSSRStrategies = (state) => state.information.SSRStrategies;
export const selectSBTMAccounts = (state) => state.information.SBTMAccounts;
export const selectTimerId = (state) => state.information.timerId;
export const selectLoggingTimerId_SCP = (state) => state.information.loggingTimerId_SCP;
export const selectOnLine = (state) => state.information.onLine;
export const selectAPI_sbtm = (state) => state.information.sbtmAPI;
export const selectAPI_scp = (state) => state.information.scpAPI;
export const selectLog_sbtm = (state) => state.information.sbtmLog;
export const selectLog_scp = (state) => state.information.scpLog;
export const selectControlfilters_1 = (state) => state.information.controlfilters_1;
export const selectControlfilters_2 = (state) => state.information.controlfilters_2;
export const selectControlfilters_3 = (state) => state.information.controlfilters_3;
export const selectControlTableData = (state) => state.information.controlTableData;
export const selectBrokers = (state) => state.information.Brokers;


export default slice.reducer;
